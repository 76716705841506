import { useAuth } from '../containers/AuthProvider'
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
    const auth = useAuth()
    const navigate = useNavigate()
    const logout = async () => {
        try {
            await auth.signout()
            navigate("/login")
        } catch(e) {
            console.error(e)
            navigate("/")
        }
    }
    logout()
    return(<></>)
}

export default LogoutPage
