import { useAuth } from './AuthProvider'
import { slugify, EuiButtonEmpty, EuiLink, EuiImage, EuiIcon, EuiSideNav, htmlIdGenerator, EuiSpacer } from '@elastic/eui';
import React, { useState } from 'react'
import DefaultFooter from '../components/UI/Footer/DefaultFooter';

import { Link } from 'react-router-dom'

import { SUPPORT_EMAIL } from '../constants/resources'


const Nav = () => {
    const auth = useAuth();
    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);
    const [selectedItems, setSelectedItem] = useState('')

    const toggleOpenOnMobile = () => {
        setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };

    const activityItems = []
    const docItems = []

    let check = false;

    if (localStorage.getItem("roles").includes('fancoil')) {
        activityItems.push({
            name: 'Gestione terminali',
            id: htmlIdGenerator('')(),
            href: '/fancoil',
            icon: <EuiIcon type="tableDensityExpanded" />
            //renderItem: () => <Link style={{ fontSize: 16 }} to="/fancoil">Gestione terminali</Link>,
            //renderItem: () =>  <Link style={{ fontSize: 16 }} to="/fancoil">Gestione terminali</Link>
        })
        docItems.push({
            name: 'Zone Termiche',
            icon: <EuiIcon type="visMapRegion" />,
            href: 'https://drive.google.com/file/d/1Y2ujI5svp0P6oXonX_MDgR5BdpwYSOhh/view',
            target: '_blank'
        })
        check = true
    }

    if (localStorage.getItem("roles").includes('illuminazione')) {
        activityItems.push({
            name: 'Gestione Illuminazione',
            id: htmlIdGenerator('')(),
            href: '/illuminazione',
            icon: <EuiIcon type="tableDensityExpanded" />
            //renderItem: () => <Link style={{ fontSize: 16 }} to="/illuminazione">Gestione illuminazione</Link>
            //renderItem: () =>  <EuiButtonEmpty href="/illuminazione">Gestione illuminazione</EuiButtonEmpty>
        })
    }

    if (localStorage.getItem("roles").includes('uta')) {
        activityItems.push({
            name: 'Gestione UTA',
            id: htmlIdGenerator('')(),
            href: '/uta',
            icon: <EuiIcon type="tableDensityExpanded" />
            // renderItem: () => <Link style={{ fontSize: 16 }} to="/uta">Gestione UTA</Link>
            // renderItem: () =>  <EuiButtonEmpty href="/uta">Gestione UTA</EuiButtonEmpty>
        })
        if (!check) {
            docItems.push({
                name: 'Zone Termiche',
                icon: <EuiIcon type="visMapRegion" />,
                href: 'https://drive.google.com/file/d/1Y2ujI5svp0P6oXonX_MDgR5BdpwYSOhh/view',
                target: '_blank'
            })
        }
    }

    docItems.push({
        name: 'Help',
        icon: <EuiIcon type="help" />,
        href: 'mailto:' + SUPPORT_EMAIL
    })


    const items = [
        {
            name: auth.user || 'placeholder.username',
            id: htmlIdGenerator('')(),
            icon: <EuiIcon size='l' type="user" />,
        },
        {
            name: 'Attività',
            id: htmlIdGenerator('')(),
            items: activityItems
        },
        {
            name: 'Documentazione',
            items: docItems
        },
        {
            name: 'Logout',
            id: htmlIdGenerator('')(),
            href: '/logout',
            icon: <EuiIcon type="exit" />
            // renderItem: () => <Link style={{ fontSize: 16 }} to="/logout">Logout</Link>,
            // renderItem: () =>  <EuiButtonEmpty iconType='exit' href="/logout">Logout</EuiButtonEmpty>
        },
    ]


    return (<>
        <EuiLink href="/">
            <EuiImage size="m" url="/eos3-logo-sticky.png" alt="" hasShadow />
        </EuiLink>
        <EuiSpacer size='l' />
        <EuiSideNav
            aria-label="Menu"
            mobileTitle="Menu"
            toggleOpenOnMobile={() => toggleOpenOnMobile()}
            isOpenOnMobile={isSideNavOpenOnMobile}
            style={{ width: 192 }}
            items={items}
        /></>)
}

{/* <Link to="/fancoil">Termico</Link>
            <Link to="/uta">Aule</Link>
            <Link to="/illuminazione">Illuminazione</Link> */}

export default Nav
