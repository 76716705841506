import React, { useState, useEffect } from 'react';
import {
  EuiInMemoryTable,
  EuiButton,
  EuiButtonIcon,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiHealth,
  EuiBadge,
  EuiText,
  EuiGlobalToastList
} from '@elastic/eui';

import { ConfirmModal } from './RegulationForm';

import moment from 'moment'

import { API_URL } from '../constants/resources'
import { useAuth } from './AuthProvider';

const SUCCESS_NOTIFICATION = {
  id: 'placeholder',
  title: 'Regolazione impostata con successo',
  iconType: 'check',
  color: 'success'
}

const FAIL_NOTIFICATION = {
  id: 'placeholder',
  title: 'Errore',
  text: <p>Riprova o contatta il supporto.</p>,
  iconType: 'alert',
  color: 'danger'
}

const columns = [
  {
    field: 'uta',
    name: 'UTA',
    sortable: true,
    truncateText: true,
  },
  {
    field: 'classrooms',
    name: 'Aule Servite',
    truncateText: false,
    render: (classrooms) => <EuiFlexGroup gutterSize="s" wrap>{classrooms.split(',').map((c, i) => <EuiFlexItem key={i} grow={false}><EuiBadge>{c}</EuiBadge></EuiFlexItem>)}</EuiFlexGroup>
  },
  {
    field: 'warmup_time',
    name: 'Pre accensione',
    truncateText: true,
    render: (data) => moment.utc(data / 1000000).format('HH:mm')
  },
  {
    field: 'cooldown_time',
    name: 'Pre spegnimento',
    truncateText: true,
    render: (data) => moment.utc(data / 1000000).format('HH:mm')
  },
  {
    field: 'enabled',
    name: 'Abilitazione',
    truncateText: true,
    render: (enabled) => <StateIcon ok={enabled} />,
  }
]

const StateIcon = ({ ok }) => {
  return ok ? <EuiButtonIcon color="success" iconType="check" /> : <EuiButtonIcon color="danger" iconType="cross" />
}


const time_options = (target, step, units) => {
  let options = []
  options.push({
    value: 'undefined',
    inputDisplay: (
      <EuiText style={{ lineHeight: 'inherit' }}>
        NON SELEZIONATO
      </EuiText>
    ),
  })
  let date = moment("2013-05-09T00:00:00")
  while (date.format('HH:mm') !== target) {
    options.push(
      {
        value: `${date.format('HH')}h${date.format('mm')}m`,
        inputDisplay: (
          <EuiText style={{ lineHeight: 'inherit' }}>
            {date.format('HH:mm')}
          </EuiText>
        ),
      }
    )
    date.add(step, units)
  }
  return options
}

const options = [
  {
    value: 'undefined',
    inputDisplay: (
      <EuiHealth color="secondary" style={{ lineHeight: 'inherit' }}>
        NON SELEZIONATO
      </EuiHealth>
    ),
  },
  {
    value: 'disabled',
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: 'inherit' }}>
        DISABILITA
      </EuiHealth>
    ),
  },
  {
    value: 'enabled',
    inputDisplay: (
      <EuiHealth color="success" style={{ lineHeight: 'inherit' }}>
        ABILITA
      </EuiHealth>
    ),
  },
];




function UTALogic() {
  const auth = useAuth()
  const [warmup_time, setWarmup] = useState('undefined');
  const [cooldown_time, setCooldown] = useState('undefined');
  const [selectedItems, setSelectedItems] = useState([])
  const [enabled, setEnabled] = useState('undefined');
  const [items, setItems] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [notifications, setNotifications] = useState([])

  const removeToast = (t) => setNotifications((notifications) => (notifications.filter(toast => toast.id !== t.id)))

  const sorting = {
    sort: {
      field: "uta",
      direction: 'asc',
    },
    enableAllColumns: false,
    readonly: false,
  };


  const search = {
    box: {
      incremental: true,
      schema: true,
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'uta',
        name: 'UTA',
        multiSelect: true,
        options: items.map(({ uta }) => {
          return {
            value: uta,
            name: uta,
            view: uta
          }
        }).sort((v1, v2) => v1.value.localeCompare(v2.value))
      }
    ],
  }

  useEffect(() => {
    const update = async () => {
      const response = await fetch(API_URL + '/v1/utas/all', {
        headers: { 'Authorization': 'Bearer ' + auth.token },
      })
      const data = await response.json()
      console.log(data,moment.utc(data[15]/1000000))
      setItems(data)
    }
    update()
  }, [])


  const handleSelection = (items) => {
    setSelectedItems(items)
  }

  const onOk = () => {
    setShowConfirm(false)
    const toSend = selectedItems.map(uta => {
      return {
        uta: uta.uta,
        ...(enabled !== 'undefined' && { enabled: enabled === 'enabled' ? true : false  }),
        ...(warmup_time !== 'undefined' && { warmup_time: warmup_time }),
        ...(cooldown_time !== 'undefined' && { cooldown_time: cooldown_time })
      }
    })
    console.log(toSend)
    const update = async () => {
      const response = await fetch(API_URL + '/v1/utas/all', {
        headers: { 'Authorization': 'Bearer ' + auth.token },
        method: 'PATCH',
        body: JSON.stringify(toSend)
      })
      if (response.ok) {
        toSend.forEach(modifiedItem => {
          const i = items.findIndex(x => x.uta === modifiedItem.uta)
          setItems((items) => {
            console.log(modifiedItem)
            items[i] = { 
              ...items[i], 
              ...(modifiedItem.enabled !== undefined && {enabled: modifiedItem.enabled}),
              ...(modifiedItem.warmup_time && ({warmup_time:moment.duration(modifiedItem.warmup_time.replace('h',':').replace('m','')).asMilliseconds() * 1000000})),
              ...(modifiedItem.cooldown_time && ({cooldown_time: moment.duration(modifiedItem.cooldown_time.replace('h',':').replace('m','')).asMilliseconds() * 1000000})),
             }
             console.log(items[i],{
              ...(modifiedItem.enabled && {enabled: modifiedItem.enabled}),
             })
            return items
          })
        })
                notifySuccess()
      }
      else {
        notifyFail()
      }
    }
    update()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setShowConfirm(true)
  }

  const notifyFail = () => {
    setNotifications((notifications) => (
      notifications.concat([{
        ...FAIL_NOTIFICATION,
        id: moment().unix().toString()
      }])
    ))
  }

  const notifySuccess = () => {
    setNotifications((notifications) => (
      notifications.concat([{
        ...SUCCESS_NOTIFICATION,
        id: moment().unix().toString()
      }])
    ))
  }

  const confirmModal = <ConfirmModal handleConfirm={() => onOk()} handleCancel={() => setShowConfirm(false)} />


  return (
    <>
      {showConfirm && confirmModal}
      <EuiGlobalToastList
        toasts={notifications}
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
      />
      <EuiInMemoryTable
        isSelectable
        isExpandable
        search={search}
        tableCaption="UTA Table"
        itemId="uta"
        items={items}
        columns={columns}
        pagination={true}
        sorting={sorting}
        selection={{ onSelectionChange: handleSelection }}
        loading={items.length === 0}
      />
      <EuiForm style={{ alignItems: 'center' }} component="form">
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="Pre accensione">
              <EuiSuperSelect
                compressed
                disabled={selectedItems.length === 0}
                options={time_options('06:00', 30, 'minutes')}
                valueOfSelected={warmup_time}
                onChange={(value) => setWarmup(value)}
              />
              {/* <EuiDatePicker
                disabled={selectedItems.length === 0}
                showTimeSelect
                showTimeSelectOnly
                selected={startDate}
                onChange={handleChange}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                maxTime={moment({ hour: 3, minute: 0 })}
                minTime={moment({ hour: 0, minute: 0 })}
              /> */}
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Pre spegnimento">
              <EuiSuperSelect
                compressed
                disabled={selectedItems.length === 0}
                options={time_options('06:00', 30, 'minutes')}
                valueOfSelected={cooldown_time}
                onChange={(value) => setCooldown(value)}
              />
              {/* <EuiDatePicker
                disabled={selectedItems.length === 0}
                showTimeSelect
                showTimeSelectOnly
                selected={startDate2}
                onChange={handleChange2}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                maxTime={moment({ hour: 3, minute: 0 })}
                minTime={moment({ hour: 0, minute: 0 })}
              /> */}
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Abilitazione">
              <EuiSuperSelect
                compressed
                disabled={selectedItems.length === 0}
                options={options}
                valueOfSelected={enabled}
                onChange={(value) => setEnabled(value)}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace>
              <EuiButton
                size='s'
                disabled={selectedItems.length === 0}
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >Invio</EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </>
  );
}

export default UTALogic;
