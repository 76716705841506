import React from 'react'
import { EuiPageTemplate, EuiEmptyPrompt } from '@elastic/eui';

function NotFoundPage({ button = <></>, title, content, sideNav }) {
  return (
    <EuiPageTemplate
      template="centeredBody"
      pageContentProps={{ paddingSize: 'l' }}
      pageSideBar={sideNav}
    >
      <EuiEmptyPrompt
        title={<span>{title}</span>}
        body={content}
        actions={button}
      />
    </EuiPageTemplate>
  );
}

export default NotFoundPage
