import React from 'react'
import moment from 'moment'

import './Footer.css'

const DefaultFooter = ({ copyright, from }) => {
  const now = moment().year()
  const text = (now <= from) ? from : `${from}-${now}`
  return (
    <footer>
      <span className="copy"> © {copyright} {text}</span>
    </footer>

  )
}

export default DefaultFooter
