import React, { useState, useEffect } from 'react';

import {
    EuiButton,
    EuiCard,
    EuiFlexItem,
    EuiSpacer,
    EuiFlexGrid,
    EuiLoadingSpinner
} from '@elastic/eui';

import { ConfirmModal } from './RegulationForm';

import { API_URL } from '../constants/resources'
import { useAuth } from './AuthProvider';

const LightsLogic = () => {

    const auth = useAuth()

    const [data, updateData] = useState([])
    const [plants, setPlants] = useState({})

    const onLightToggle = (plant, floor) => {
        return (enabled) => {
            const i = data.findIndex(d => d.plant === plant && d.floor === floor)
            const elem = data[i]
            fetch(API_URL + '/v1/lights/' + elem.id, {
                headers: { 'Authorization': 'Bearer ' + auth.token },
                method: 'PATCH',
                body: JSON.stringify({ enabled: enabled })
            }).then(res => {
                console.log(res)
            })
            updateData(data => {
                const i = data.findIndex(d => d.plant === plant && d.floor === floor)
                data[i].enabled = enabled
                return data
            })
        }
    }

    const isEnabled = (plant, floor) => {
        return data.find(d => d.plant === plant && d.floor === floor).enabled
    }

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(API_URL + '/v1/lights/all', {
                headers: { 'Authorization': 'Bearer ' + auth.token },
            })
            const data = await response.json()
            let obj = {}
            data.forEach(d => {
                if (obj[d.plant] === undefined) {
                    obj[d.plant] = [d.floor]
                } else {
                    obj[d.plant].push(d.floor)
                }
            })
            for (let item in obj) {
                obj[item].sort()
                obj[item].reverse()
            }
            updateData(data)
            setPlants(obj)
        }
        fetchData();
    }, [])

    if (data.length === 0) {
        return <EuiLoadingSpinner />
    }

    return (
        <EuiFlexGrid columns={3} gutterSize="l">
            {
                Object.entries(plants).map((item, i) => {
                    const [plant, floors] = item
                    return (
                        <EuiFlexItem key={i}>
                            <EuiCard title={"Edificio " + plant} footer={floors.map((floor, i) => <FloorButton enabled={isEnabled(plant, floor)} floor={floor} updateListener={onLightToggle(plant, floor)} key={i} />)}
                            />
                        </EuiFlexItem>)
                })
            }
        </EuiFlexGrid>
    );
}

function FloorButton({ enabled, floor, updateListener }) {
    const [status, setStatus] = useState(enabled)
    const [showConfirm, setShowConfirm] = useState(false)
    const onButtonClick = () => {
        setShowConfirm(true)
    }

    const onOk = () => {
        setShowConfirm(false)
        updateListener(!status)
        setStatus(!status)
    }
    const confirmModal = <ConfirmModal handleConfirm={() => onOk()} handleCancel={() => setShowConfirm(false)} />
    return (
        <>
            {showConfirm && confirmModal}
            <EuiButton
                style={{ fontSize: 18 }}
                fullWidth
                color={status ? 'warning' : 'primary'}
                fill={status}
                iconType="bolt"
                size="m"
                onClick={onButtonClick}>
                Piano {floor === 0 ? "terra" : floor}
            </EuiButton>
            <EuiSpacer size="xs" />
        </>
    )
}


export default LightsLogic
