//todo spostare comandi verso api in componente dedicato

import React from 'react'
import moment from 'moment'

import DashboardPage from '../components/UI/DashboardPage'
import RegulationForm from './RegulationForm'
import { useAuth4Class } from './AuthProvider'

import { API_URL, STORE_NAME, PLANT_NAME } from '../constants/resources'

import {
  EuiInMemoryTable,
  EuiIcon,
  EuiBadge,
  EuiSpacer,
  EuiGlobalToastList,
  Toast
} from '@elastic/eui';



const SUCCESS_NOTIFICATION = {
  id: 'placeholder',
  title: 'Regolazione impostata con successo',
  iconType: 'check',
  color: 'success'
}

const FAIL_NOTIFICATION = {
  id: 'placeholder',
  title: 'Errore',
  text: <p>Riprova o contatta il supporto.</p>,
  iconType: 'alert',
  color: 'danger'
}

const COLUMNS_OPT = [
  {
    field: 'plant',
    name: 'Edificio',
    dataType: 'string',
    align: 'center',
  },
  {
    field: 'floor',
    name: 'Piano',
    dataType: 'number',
    align: 'center',
  },
  {
    field: 'thermal_zone',
    name: 'Zona Termica',
    dataType: 'string',
    align: 'center',
  },
  {
    field: 'winter_setpoint',
    name: 'Setpoint Invernale',
    align: 'center',
    sortable: true,
    render: (value) => `${value}°C`
  },
  {
    field: 'summer_setpoint',
    name: 'Setpoint Estivo',
    align: 'center',
    sortable: true,
    render: (value) => `${value}°C`
  },
  {
    field: 'season',
    name: 'Stagione',
    align: 'center',
    sortable: true,
    render: (value) => {
      switch (value) {
        case 'winter':
          return <EuiBadge color="#79B1D8">INVERNO</EuiBadge>
        case 'summer':
          return <EuiBadge color="#FAD179">ESTATE</EuiBadge>
        default:
          return <EuiBadge color="danger">SCONOSCIUTO</EuiBadge>
      }
    }
  },
  {
    field: 'write',
    name: 'Abilitazione',
    align: 'center',
    sortable: true,
    render: (value) => (value) ?
      <EuiIcon size="l" type="check" color="success" /> :
      <EuiIcon size="l" type="cross" color="danger" />,
  },
]

class FancoilLogic extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      selectedItems: [],
      notifications: [],
      items: []
    }
  }

  componentDidMount = () => {
    fetch(`${API_URL}/v1/fancoils/all`, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
      //credentials: 'include'
    }).then(res => {
      if (res.ok) return res.json()
      else throw Error(res.statusText)
    })
      .then((data) => this.setState({ items: data.sort((a, b) => a.thermal_zone.localeCompare(b.thermal_zone)), loading: false }))
      .catch((err) => {
        console.log(err)
        this.setState({ connectionError: 'Dati non disponibili', loading: false })
        this.notifyFail()
      })
  }

  handleSelection = (items) => {
    this.setState({ selectedItems: items.map(i => i.thermal_zone) })
  }

  //todo rivedere
  handleForm = (data) => {
    console.log(data)
    // const username = localStorage.getItem('username') || ''
    // const now = moment().unix()
    const toSend = this.state.selectedItems.map(item => {
      const i = this.state.items.findIndex(x => x.thermal_zone === item)
      return { ...this.state.items[i], ...data }
    })
    let headers = new Headers();
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'))
    fetch(`${API_URL}/v1/fancoils/all`, {
      method: 'PATCH',
      body: JSON.stringify(toSend),
      headers: headers
      //credentials: 'include'
    }).then((res) => {
      if (!res.ok) throw Error('')
      else this.notifySuccess()
      toSend.forEach(f => {
        const i = this.state.items.findIndex(x => x.thermal_zone === f.thermal_zone)
        this.setState(state => state.items[i] = { ...state.items[i], ...f })
      })
    }).catch((err) => {
      console.error(err)
      this.notifyFail()
    })
  }

  notifyFail = () => {
    this.setState((state) => ({
      notifications: state.notifications.concat([{
        ...FAIL_NOTIFICATION,
        id: moment().unix().toString()
      }])
    }))
  }

  notifySuccess = () => {
    this.setState((state) => ({
      notifications: state.notifications.concat([{
        ...SUCCESS_NOTIFICATION,
        id: moment().unix().toString()
      }])
    }))
  }

  removeToast = (t) => {
    this.setState((state) => ({ notifications: state.notifications.filter(toast => toast.id !== t.id) }))
  }

  render = () => {
    const tableProps = (!!this.state.connectionError) ? { error: this.state.connectionError } : {}
    return (<>
      <EuiGlobalToastList
        toasts={this.state.notifications}
        dismissToast={this.removeToast}
        toastLifeTimeMs={6000}
      />
      <EuiInMemoryTable
        {...tableProps}
        selection={{ onSelectionChange: this.handleSelection }}
        itemId="thermal_zone"
        items={this.state.items}
        columns={COLUMNS_OPT}
        loading={this.state.loading}
        sorting={true}
        search={
          {
            box: {
              incremental: true,
              schema: true,
            },
            filters: [
              {
                type: 'field_value_selection',
                field: 'plant',
                name: 'Edificio',
                multiSelect: false,
                options: Array.from(new Set(this.state.items.map(({ plant }) => plant))).map(plant => {
                  return {
                    value: plant,
                    name: plant,
                    view: plant
                  }
                }).sort((v1, v2) => v1.value.localeCompare(v2.value))
              },
              {
                type: 'field_value_selection',
                field: 'floor',
                name: 'Piano',
                multiSelect: false,
                options: Array.from(new Set(this.state.items.map(({ floor }) => floor))).map(floor => {
                  return {
                    value: floor,
                    name: floor,
                    view: floor
                  }
                }).sort((v1, v2) => v1.value.toString().localeCompare(v2.value.toString()))
              },
              {
                type: 'field_value_selection',
                field: 'thermal_zone',
                name: 'Zona Termica',
                multiSelect: false,
                options: Array.from(new Set(this.state.items.map(({ thermal_zone }) => thermal_zone))).map(thermal_zone => {
                  return {
                    value: thermal_zone,
                    name: thermal_zone,
                    view: thermal_zone
                  }
                }).sort((v1, v2) => v1.value.localeCompare(v2.value))
              }
            ],
          }
        }
        pagination={{
          initialPageSize: 10,
          pageSizeOptions: [5, 10, 50, 100, 250, 500]
        }}
      />
      <RegulationForm listener={this.handleForm} allowSubmit={this.state.selectedItems.length > 0} />
    </>
    )
  }
}


export default FancoilLogic
