import React, { useEffect, useState } from 'react'

import { useAuth } from '../containers/AuthProvider'

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiFieldText,
  EuiFieldPassword,
  EuiCallOut,
  EuiSpacer
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import DefaultFooter from '../components/UI/Footer/DefaultFooter';

const ERR_CALLOUT = <EuiCallOut color="danger" title="Errore generico">Riprova o contatta il supporto.</EuiCallOut>
const UNAUTHORIZED_CALLOUT = <EuiCallOut color="danger" title="Credenziali errate">Riprova o contatta il supporto.</EuiCallOut>

function LoginPage() {
  const auth = useAuth()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [err, setErr] = useState(undefined)
  const navigate = useNavigate()


  const handleSubmit = (event) => {
    const handler = async () => {
      event.preventDefault()
      try {
        await auth.signin({ username: username, password: password })
        navigate('/');
      } catch (e) {
        setErr(UNAUTHORIZED_CALLOUT)
      }
    }
    handler()
  }
  return (
    <EuiPage>
      <EuiPageBody>
        <DefaultFooter copyright="Eurix" from={2019} />
        <EuiPageContent verticalPosition="center" horizontalPosition="center">
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              {/* <EuiTitle> */}
              <EuiImage size="m" url="/eos3-logo-sticky.png" alt="eos³-logo" />
              {/* <h2>{title.toUpperCase()}</h2> */}
              {/* </EuiTitle> */}
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiForm>
              <form onSubmit={handleSubmit}>
                {err ? err : null}
                <EuiSpacer />
                <EuiFlexGroup style={{ maxWidth: '50vw' }}>
                  <EuiFlexItem>
                    <EuiFormRow label="Username">
                      <EuiFieldText icon="user" value={username} onChange={(event) => setUsername(event.target.value)} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label="Password">
                      <EuiFieldPassword value={password} onChange={(event) => setPassword(event.target.value)} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow hasEmptyLabelSpace>
                      <EuiButton type='submit'>Login</EuiButton>
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </form>
            </EuiForm>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default LoginPage
