import React from 'react'

import { fakeAuthProvider } from '../api/auth'
import { API_URL } from '../constants/resources'

import jwtDecode from 'jwt-decode'

let AuthContext = React.createContext(null);

export function useAuth() {
    return React.useContext(AuthContext);
}

export function useAuth4Class() {
    return React.createContext()
}

function AuthProvider({ children }) {
    const [user, setUser] = React.useState(localStorage.getItem('username'));
    const [token, setToken] = React.useState(localStorage.getItem('token'))

    const signin = async ({ username, password }) => {
        const res = await fetch(API_URL + '/v1/auth/login', {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
        if (!res.ok) {
            throw Error('unauthorized')
        }
        const data = await res.json()
        const decoded = jwtDecode(data.token)
        setToken(data.token)
        setUser(username)
        localStorage.setItem("roles", decoded.roles)
        localStorage.setItem("token", data.token)
        localStorage.setItem("username", username)
    }

    const signout = async () => {
        setUser(null)
        setToken(null)
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        const res = await fetch(API_URL + '/v1/auth/logout', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        if (!res.ok) {
            throw Error('unauthorized')
        }
    };

    let value = { user, token, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

}

export default AuthProvider
