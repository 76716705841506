import React, { useState } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiFieldNumber,
  EuiSpacer,
  EuiSuperSelect,
  EuiHealth,
  EuiConfirmModal,
  EuiOverlayMask,
} from '@elastic/eui';

const DEFAULT_SP_PROPS = {
  step: 0.1,
  min: 16,
  max: 26,
  placeholder: 'INVARIATO'
}

const SEASON_OPTIONS = [
  {
    value: 'untouched',
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: 'inherit' }}>
        INVARIATO
      </EuiHealth>
    ),
  },
  {
    value: 'summer',
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: 'inherit' }}>
        ESTATE
      </EuiHealth>
    ),
  },
  {
    value: 'winter',
    inputDisplay: (
      <EuiHealth color="primary" style={{ lineHeight: 'inherit' }}>
        INVERNO
      </EuiHealth>
    ),
  },
];


const EFFICIENCY_STATUS_OPTIONS = [
  {
    value: 'untouched',
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: 'inherit' }}>
        INVARIATO
      </EuiHealth>
    ),
  },
  {
    value: 'false',
    inputDisplay: (
      <EuiHealth color="danger" style={{ lineHeight: 'inherit' }}>
        OFF
      </EuiHealth>
    ),
  },
  {
    value: 'true',
    inputDisplay: (
      <EuiHealth color="success" style={{ lineHeight: 'inherit' }}>
        ON
      </EuiHealth>
    ),
  },
];


export const ConfirmModal = ({ handleCancel, handleConfirm }) => {
  return <EuiOverlayMask>
    <EuiConfirmModal
      title="Confermi la regolazione?"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      cancelButtonText="Annulla"
      confirmButtonText="Confermo"
      defaultFocusedButton="confirm"
    >
      {/* <EuiDescriptionList textStyle="reverse" listItems={[]} /> */}
    </EuiConfirmModal>
  </EuiOverlayMask>
}



const RegulationForm = ({listener, allowSubmit}) => {

  const [winterSP, setWinterSP] = useState('')
  const [summerSP, setSummerSP] = useState('')
  const [season, setSeason] = useState('untouched')
  const [write, setWrite] = useState('untouched')

  const [showConfirm, setShowConfirm] = useState(false)

  const handleWinterSP = (event) => {
    const value = parseFloat(event.target.value)
    setWinterSP(isNaN(value) ? '' : value)
  }

  const handleSummerSP = (event) => {
    const value = parseFloat(event.target.value)
    setSummerSP(isNaN(value) ? '' : value)
  }

  const handleEnabled = (value) => {
    switch (value) {
      case 'true':
        setWrite(true)
        break;
      case 'false':
        setWrite(false)
        break;
      default:
        setWrite('untouched')

    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setShowConfirm(true)
  }

  const resetForm = () => {
    setWinterSP('')
    setSummerSP('')
    setSeason('untouched')
    setWrite('untouched')
  }

  const handleModalCancel = () => {
    setShowConfirm(false)
    resetForm()
  }

  const handleModalConfirm = () => {
    setShowConfirm(false)
    let data = {}
    if (winterSP !== '') data.winter_setpoint = winterSP
    if (summerSP !== '') data.summer_setpoint = summerSP
    if (season !== 'untouched') data.season= season
    if (write !== 'untouched') data.write = write
    listener(data)
    resetForm()
  }

  const shouldDisableSubmitButton = () => {
    return winterSP === '' && summerSP === '' && season === 'untouched' && write === 'untouched'
  }

  const confirmModal = showConfirm ? 
  <ConfirmModal
  handleCancel={handleModalCancel} 
  handleConfirm={handleModalConfirm} /> : null

  return (
    <EuiForm style={{ alignItems: 'center' }}>
      {confirmModal}
      <form onSubmit={handleSubmit}>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="Setpoint invernale">
              <EuiFieldNumber
                {...DEFAULT_SP_PROPS}
                value={winterSP}
                onChange={handleWinterSP}
                disabled={!allowSubmit}
                compressed />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Setpoint estivo">
              <EuiFieldNumber
                {...DEFAULT_SP_PROPS}
                value={summerSP}
                onChange={handleSummerSP}
                disabled={!allowSubmit}
                compressed />
            </EuiFormRow>
          </EuiFlexItem>
          {/* <EuiFlexItem>
            <EuiFormRow label="Stagione">
              <EuiSuperSelect
                options={[...SEASON_OPTIONS]}
                valueOfSelected={season}
                onChange={setSeason}
                disabled={!allowSubmit}
                compressed
              />
            </EuiFormRow>
          </EuiFlexItem> */}
          <EuiFlexItem>
            <EuiFormRow label="Abilitazione">
              <EuiSuperSelect
                options={[...EFFICIENCY_STATUS_OPTIONS]}
                valueOfSelected={write.toString()}
                onChange={handleEnabled}
                disabled={!allowSubmit}
                compressed
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace>
              <EuiButton type='submit' size="s" disabled={shouldDisableSubmitButton() || !allowSubmit}>Imposta regolazione</EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </form>
    </EuiForm>
  )
}


export default RegulationForm
