import React from 'react'
import {
  EuiPageTemplate,
  EuiPage
} from '@elastic/eui'

function LightsPage({ button = <></>, title, content, sideNav }) {
  return (
    <EuiPageTemplate
      minHeight='100vh'
      //fullHeight='noscroll'
      template="default"
      pageContentProps={{ paddingSize: 'none' }}
      pageSideBar={sideNav}
      pageHeader={{
        //iconType: 'logoElastic',
        pageTitle: title,
        rightSideItems: [button],
      }}
    >
      {content}
    </EuiPageTemplate>
  )
}

export default LightsPage
