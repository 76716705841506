import React from 'react';
import '@elastic/eui/dist/eui_theme_light.css';

import { EuiProvider } from '@elastic/eui';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import HomePage from './pages/HomePage';
import FancoilPage from './pages/FancoilPage';
import UTAPage from './pages/UTAPage';
import LightsPage from './pages/LightsPage';
import NotFoundPage from './pages/errors/NotFoundPage';

import RequireAuth from './components/RequireAuth'
import LoginPage from './pages/LoginPage';
import AuthProvider from './containers/AuthProvider';

import FancoilLogic from './containers/FancoilLogic';
import UTALogic from './containers/UTALogic';

import Nav from './containers/Nav'
import LightsLogic from './containers/LightsLogic';
import LogoutPage from './pages/LogoutPage';

import DefaultFooter from './components/UI/Footer';

import { EuiCollapsibleNavGroup, EuiListGroup, EuiSpacer, EuiButton } from '@elastic/eui'
const deploymentsList = []
const testNav = <EuiCollapsibleNavGroup
  title={
    <span>
      <small style={{ fontWeight: 'normal' }}>Deployment</small> <br />
      <strong>personal-databoard</strong>
    </span>
  }
  iconType="logoGCPMono"
  iconSize="xl"
  isCollapsible={true}
  initialIsOpen={false}
  background="dark"
>
  <div role="group" className="kibanaNavDeployment__content">
    <EuiListGroup listItems={deploymentsList} flush />
    <EuiSpacer size="s" />
    <EuiButton color="ghost" fullWidth>
      Manage deployments
    </EuiButton>
  </div>
</EuiCollapsibleNavGroup>


const homePageContent = "Selezionare la dashboard richiesta dalla barra di navigazione."

const App = () => (
    <BrowserRouter>
      <EuiProvider colorMode="light">
        <AuthProvider>
          <Routes>
            <Route path="/" element={
              <RequireAuth>
                <HomePage sideNav={<Nav />} title="CAMPUS LUIGI EINAUDI" content={homePageContent} />
              </RequireAuth>} />
            <Route path="/fancoil" element={
              <RequireAuth>
                <FancoilPage title="Gestione terminali" sideNav={<Nav />} content={<FancoilLogic />} />
              </RequireAuth>} />
            <Route path="/uta" element={
              <RequireAuth>
                <UTAPage title="Gestione UTA" sideNav={<Nav />} content={<UTALogic />} />
              </RequireAuth>} />
            <Route path="/illuminazione" element={
              <RequireAuth>
                <LightsPage title="Gestione illuminazione" content={<LightsLogic />} sideNav={<Nav />} />
              </RequireAuth>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={
              <RequireAuth>
                <LogoutPage />
              </RequireAuth>} />
            <Route path="*" element={<NotFoundPage title={"404"} content={"Pagina non trovata."} button={<Link to="/">Homepage</Link>} />} />
          </Routes>
        </AuthProvider>
      </EuiProvider>
    </BrowserRouter>
);

export default App
